import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import 'styles/global.css'

import Landing from '@/containers/Landing';
import Login from '@/containers/Login';
import Signup from '@/containers/Signup';
import CreateBusiness from '@/containers/CreateBusiness';
import ResetPassword from '@/containers/ResetPassword';
import SetNewPassword from '@/containers/SetNewPassword';
import Events from '@/containers/Events';
import ManageEvent from '@/containers/ManageEvent';
import Gallery from '@/containers/Gallery';
import GalleryAbout from '@/components/Gallery/GalleryAbout';
import GalleryPhotos from '@/components/Gallery/GalleryPhotos';
import GalleryFaces from '@/components/Gallery/GalleryFaces';
import GalleryFace from '@/components/Gallery/GalleryFace';
import PrivateRoute from '@/components/routes/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
        <BrowserRouter>
            <ToastContainer position="top-center" style={{ width: "450px" }} />
            <Routes>
                <Route path="/" element={<Landing />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/signup" element={<Signup />}/>
                <Route path="/create-business" element={<PrivateRoute><CreateBusiness /></PrivateRoute>}/>
                <Route path="/reset-password" element={<ResetPassword />}/>
                <Route path="/reset-password/:uid/:token" element={<SetNewPassword />}/>
                <Route path="/events"  element={ <PrivateRoute><Events/></PrivateRoute> } />
                <Route path="/events/:eventId"  element={ <PrivateRoute><ManageEvent/></PrivateRoute> } />
                <Route path="/gallery/:eventSlug" element={<Gallery />}>
                    <Route index element={<Navigate to="about" />} />
                    <Route path="about" element={<GalleryAbout />}/>
                    <Route path="photos" element={<GalleryPhotos />}/>
                    <Route path="faces" element={<GalleryFaces />}/>
                </Route>
                <Route path="/gallery/:eventSlug/faces/:faceId" element={<GalleryFace />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
