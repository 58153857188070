import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { Button } from '@/components/ui/button';
import { Trash2 } from 'lucide-react';
import { getEvent, startPollingEvent, stopPollingEvent, deleteEvent, editEvent } from '@/redux/actions';
import { Link, useParams } from 'react-router-dom';
import withHeaderAndFooter from '@/components/HOCs/withHeaderAndFooter';
import EventDetails from '@/components/Events/EventDetails';
import { Card, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import Stepper from '@/components/Photos/Stepper';
import FileUploader from '@/components/Photos/FileUploader';
import { getSteps, getCurrentStep, enabledNextStep } from '@/utils/tools';
import DownloadGroupsStep from '@/components/Photos/DownloadGroupsStep';

const ManageEvent = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: businessId, monthly_photo_quota, current_month_photos_count } = useSelector(state => state.businesses?.business);
    const { isEventLoading, isPolling, isEditEventLoading } = useSelector(state => state.events.state);
    const event  = useSelector(state => state.events.event);
    const { photos_count: photosCount, portraits_count: portraitsCount, classification, status, photo_groups_zip_url: photoGroupsZipUrl } = event;
    const steps = useMemo(() => getSteps(classification), [classification]);
    const initialStep = useMemo(() => getCurrentStep(photosCount, portraitsCount, classification), [photosCount, portraitsCount, classification]);
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedEvent, setEditedEvent] = useState({ title: '', description: '', startingDate: '', endingDate: '', mainImage: '', mainImageFile: null, isPublic: false });
    const [errors, setErrors] = useState({});
    const isDeletable = (status.toLowerCase() === 'draft');
    const remainingPhotoQuota =  monthly_photo_quota - current_month_photos_count;

    useEffect(() => {
        if (event) {
            setEditedEvent({
                title: event.title,
                description: event.description,
                startingDate: event.starting_date,
                endingDate: event.ending_date,
                mainImage: event.main_image,
                isPublic: event.public
            });
        }
    }, [event]);

    useEffect(() => {
        setCurrentStep(initialStep);
    }, [initialStep]);

    useEffect(() => {
        eventId && businessId && dispatch(getEvent({ businessId, eventId }));
    }, [dispatch, businessId, eventId]);

    useEffect(() => {
        if (!status) return;

        if (status.toLowerCase() === 'processing') {
            !isPolling && dispatch(startPollingEvent({ businessId, eventId }));
        } else {
            isPolling && dispatch(stopPollingEvent());
        }
    }, [dispatch, businessId, eventId, status, isPolling]);

    const handleSaveChanges = () => {
        const newErrors = {};
        const { title, description, startingDate, endingDate, mainImage, mainImageFile, isPublic } = editedEvent;
        if (!title) newErrors.title = 'Title is required';
        if (!startingDate) newErrors.date = 'Date is required';

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        if (title && title !== event.title) {
            formData.append('title', title);
        }
        if (description !== event.description) {
            formData.append('description', description);
        }
        if (startingDate && startingDate !== event.starting_date) {
            formData.append('starting_date', startingDate);
        }
        if (endingDate !== event.ending_date) {
            formData.append('starting_date', startingDate);
            formData.append('ending_date', endingDate);
        }
        if (mainImageFile && mainImage && mainImage !== event.main_image) {
            formData.append('main_image', mainImageFile);
        }
        if (isPublic !== event.public) {
            formData.append('public', isPublic);
        }

        Array.from(formData.keys()).length > 0 && dispatch(editEvent({ businessId, eventId, formData }));
        setIsEditMode(false);
    }

    const handleDelete = () => {
        return isDeletable && dispatch(deleteEvent({ businessId, eventId, navigate }));
    }

    if (isEventLoading || isEditEventLoading) {
        return (
        <main className="flex flex-col items-center min-h-screen bg-gray-100 p-4 sm:px-6 sm:py-0">
                <Spinner className="mt-44" size="xlarge">
                    <span className="py-3 text-gray-800">Loading your event...</span>
                </Spinner>
        </main>
        )
    }
    return (
        <div>
            <main className="flex flex-col items-center bg-gray-100 p-4 sm:px-6 sm:py-0">
                <Card className="w-full max-w-screen-2xl mt-6">
                    <CardHeader className="flex justify-between text-left">
                        <CardTitle className="text-xl font-weight-400 text-neutral-800 flex justify-between sm:px-6">
                            <Link to="/events">←&nbsp;&nbsp;Events</Link>
                            <div className="flex items-center space-x-2">
                                <Button
                                    variant={isEditMode ? 'default' : 'outline'}
                                    size="lg"
                                    className="text-lg h-10"
                                    onClick={isEditMode ? handleSaveChanges : () => setIsEditMode(true)}
                                >
                                    {isEditMode ? 'Save changes' : 'Edit event'}
                                </Button>
                                {isEditMode &&
                                    <Button
                                        variant="outline"
                                        size="lg"
                                        className="text-lg h-10"
                                        onClick={() => setIsEditMode(false)}
                                    >
                                        Cancel
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    className="text-lg h-10 flex items-center justify-center"
                                    variant={`${isDeletable ? 'destructive' : 'disabled'}`}
                                    onClick={handleDelete}
                                >
                                    <Trash2 />
                                </Button>
                            </div>
                        </CardTitle>
                    </CardHeader>
                    <EventDetails event={event} isEditMode={isEditMode} editedEvent={editedEvent} setEditedEvent={setEditedEvent} errors={errors} />
                </Card>

                <div className="flex flex-wrap lg:flex-nowrap gap-4 w-full max-w-screen-2xl my-6">
                    <div className="w-full lg:w-full">
                        <Card className="flex justify-between">
                            <div className="w-full lg:w-2/6">
                                <Stepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} classification={classification} photosCount={photosCount} portraitsCount={portraitsCount} />
                            </div>
                            <div className="lg:border-l-2 my-4 w-full lg:w-4/6 px-16">
                                {['photos', 'portraits'].includes(currentStep.value) ?
                                    <FileUploader event={event} remainingPhotoQuota={remainingPhotoQuota} currentStep={currentStep} />
                                    :
                                    <DownloadGroupsStep photoGroupsZipUrl={photoGroupsZipUrl} currentStep={currentStep} photosCount={photosCount} portraitsCount={portraitsCount} status={status} />
                                }
                                <CardFooter className="text-md font-weight-400 text-neutral-800 flex justify-between mt-8">
                                    <button
                                        onClick={!currentStep.isFirst ? () => setCurrentStep(steps[currentStep.index - 1]) : null}
                                        className={`${!currentStep.isFirst ? '' : 'text-gray-400/90 cursor-default'}`}
                                    >
                                        &larr;&nbsp;&nbsp;Previous step
                                    </button>
                                    <button
                                        onClick={enabledNextStep(currentStep, photosCount, portraitsCount) ? () => setCurrentStep(steps[currentStep.index + 1]) : null}
                                        className={`${enabledNextStep(currentStep, photosCount, portraitsCount) ? '' : 'text-gray-400/90 cursor-default'}`}
                                    >
                                        Next step&nbsp;&nbsp;&rarr;
                                    </button>
                                </CardFooter>
                            </div>
                        </Card>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default withHeaderAndFooter(ManageEvent);
