import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { CalendarDays } from 'lucide-react';
import moment from 'moment/moment';

const GalleryAbout = () => {
    const { event } = useOutletContext();
    const { description, starting_date, ending_date, photos_count, photo_groups_count } = event;

    return (
        <Card className="w-full max-w-screen-2xl mt-4 min-h-24 text-neutral-800">
            <CardContent className="p-6">
                {description &&
                    <p>{description}</p>
                }

                {starting_date &&
                    <>
                        <Separator className="my-4 bg-black bg-opacity-20"/>
                        <div className="mt-6 flex">
                            <CalendarDays className="w-5 mr-2 text-lg text-primary"/>
                            <p>
                                <span className="font-weight-500">{moment(starting_date).format("Do MMMM YYYY")}</span>
                                {ending_date && ending_date !== starting_date &&
                                    <span className="font-weight-500">&nbsp;-&nbsp;{moment(ending_date).format("Do MMMM YYYY")}</span>
                                }
                            </p>
                        </div>
                    </>
                }

                {photos_count > 0 &&
                    <>
                        <Separator className="my-4 bg-black bg-opacity-20"/>
                        <div className="mt-6 flex justify-start gap-8 align-center text-center">
                            <div>
                                <p className="font-weight-500">{photos_count}</p>
                                <p>photos</p>
                            </div>
                            <div>
                                <p className="font-weight-500">{photo_groups_count}</p>
                                <p>faces</p>
                            </div>
                        </div>
                    </>
                }

            </CardContent>
        </Card>
    );
};

export default GalleryAbout;
