export const GET_GALLERY_EVENT = 'GET_GALLERY_EVENT';
export const GET_GALLERY_EVENT_SUCCESS = 'GET_GALLERY_EVENT_SUCCESS';
export const GET_GALLERY_EVENT_FAILURE = 'GET_GALLERY_EVENT_FAILURE';

export const GET_GALLERY_PHOTOS = 'GET_GALLERY_PHOTOS';
export const GET_GALLERY_PHOTOS_SUCCESS = 'GET_GALLERY_PHOTOS_SUCCESS';
export const GET_GALLERY_PHOTOS_FAILURE = 'GET_GALLERY_PHOTOS_FAILURE';

export const GET_GALLERY_PHOTO_GROUPS = 'GET_GALLERY_PHOTO_GROUPS';
export const GET_GALLERY_PHOTO_GROUPS_SUCCESS = 'GET_GALLERY_PHOTO_GROUPS_SUCCESS';
export const GET_GALLERY_PHOTO_GROUPS_FAILURE = 'GET_GALLERY_PHOTO_GROUPS_FAILURE';

export const GET_GALLERY_PHOTO_GROUP = 'GET_GALLERY_PHOTO_GROUP';
export const GET_GALLERY_PHOTO_GROUP_SUCCESS = 'GET_GALLERY_PHOTO_GROUP_SUCCESS';
export const GET_GALLERY_PHOTO_GROUP_FAILURE = 'GET_GALLERY_PHOTO_GROUP_FAILURE';