import { takeLatest } from 'redux-saga/effects';

import * as c from '@/redux/constants/gallery';

import { getGalleryEventSaga } from './getGalleryEvent';
import { getGalleryPhotosSaga } from './getGalleryPhotos';
import { getGalleryPhotoGroupsSaga } from './getGalleryPhotoGroups';
import { getGalleryPhotoGroupSaga } from './getGalleryPhotoGroup';

export default function *gallerySaga() {
    yield takeLatest(c.GET_GALLERY_EVENT, getGalleryEventSaga);
    yield takeLatest(c.GET_GALLERY_PHOTOS, getGalleryPhotosSaga);
    yield takeLatest(c.GET_GALLERY_PHOTO_GROUPS, getGalleryPhotoGroupsSaga);
    yield takeLatest(c.GET_GALLERY_PHOTO_GROUP, getGalleryPhotoGroupSaga);
}
