import React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';


const GalleryTabMenu = ({ event }) => {
    const { eventSlug } = useParams();
    const location = useLocation();

    return (
        <div className="w-full max-w-7xl mt-2">
            <div className="flex justify-start border-b border-neutral-400 mb-4 text-neutral-800 font-weight-300 gap-6">
                <Link
                    to={`/gallery/${eventSlug}/about`}
                    className={`py-2 px-4 ${
                        location.pathname.includes('/about') ? 'border-b-2 border-primary font-weight-500' : ''
                    }`}>
                    About
                </Link>
                <Link
                    to={`/gallery/${eventSlug}/photos`}
                    className={`py-2 px-4 ${
                        location.pathname.includes('/photos') ? 'border-b-2 border-primary font-weight-500' : ''
                    } ${event.photos_count === 0 ? 'pointer-events-none text-neutral-400' : ''}`}>
                    Photos <span className="text-sm font-weight-400">({event.photos_count})</span>
                </Link>
                <Link
                    to={`/gallery/${eventSlug}/faces`}
                    className={`py-2 px-4 ${
                        location.pathname.includes('/faces') ? 'border-b-2 border-primary font-weight-500' : ''
                    } ${event.photo_groups_count === 0 ? 'pointer-events-none text-neutral-400' : ''}`}>
                    Faces <span className="text-sm font-weight-400">({event.photo_groups_count})</span>
                </Link>
            </div>
            <Outlet context={{ event }} />
        </div>
    );
};

export default GalleryTabMenu;
