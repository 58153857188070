import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@/components/ui/spinner';
import { getGalleryPhotos } from '@/redux/actions';
import { useParams } from 'react-router-dom';
import GalleryInfiniteList from '@/components/Gallery/GalleryInfiniteList';

const GalleryPhotos = ({ photoGroupId = null }) => {
    const { eventSlug } = useParams();
    const dispatch = useDispatch();
    const galleryPhotos = useSelector(state => state.gallery.galleryPhotos);
    const { isGalleryPhotosLoading } = useSelector(state => state.gallery.state);
    const { current_page, items_total_count, items_per_page, data } = galleryPhotos;
    const hasMore = useMemo(() => current_page * items_per_page < items_total_count, [current_page, items_per_page, items_total_count]);

    const fetchMorePhotos = () => {
        if (hasMore) {
            dispatch(getGalleryPhotos({ eventSlug, current_page: current_page + 1, photoGroupId }));
        }
    };

    useEffect(() => {
        dispatch(getGalleryPhotos({ eventSlug, current_page: 1, photoGroupId }));
    }, [dispatch, eventSlug, photoGroupId]);

    if (isGalleryPhotosLoading && data.length === 0) {
        return (
            <Spinner className="mt-44" size="xlarge">
                <span className="py-3 text-gray-800">Loading photos...</span>
            </Spinner>
        )
    }

    return (
        <GalleryInfiniteList
            data={data}
            hasMore={hasMore}
            next={fetchMorePhotos}
            page={'photos'}
        />

    );
};


export default GalleryPhotos;
