import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Spinner } from '@/components/ui/spinner';
import GalleryPhotoCard from '@/components/Gallery/GalleryPhotoCard';
import GalleryFaceCard from '@/components/Gallery/GalleryFaceCard';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';


const GalleryInfiniteList = ({ data, hasMore, next, page = 'photos' }) => {
    // const endMessage = `You've seen all ${page === 'faces' ? 'faces' : 'photos'}.`;

    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openLightbox = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    const images = data.map((item) => ({
        src: item.file,
        description: item?.file_name ? item.file_name.split(".")[0] : '',
    }));


    return (
        <>
            <InfiniteScroll
                dataLength={data.length}
                next={next}
                hasMore={hasMore}
                loader={<Spinner size="medium"/>}
                endMessage={<p className="p-8 text-muted-foreground text-center">&nbsp;</p>}
                className="w-full"
                style={{overflow: 'none'}}
            >
                <ResponsiveMasonry columnsCountBreakPoints={{300: 2, 500: 3, 700: 4}}>
                    <Masonry>
                        {data.map((item, index) => (
                            page === 'faces' ? (
                                <GalleryFaceCard key={item.id} face={item} />
                            ) : (
                                <GalleryPhotoCard key={item.id} photo={item} index={index} openLightbox={openLightbox} />
                            )
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </InfiniteScroll>

            {isOpen && (
                <Lightbox
                    styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)" } }}
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={images}
                    index={currentIndex}
                    onIndexChange={setCurrentIndex}
                    plugins={[Captions, Thumbnails]}
                    captions={{ descriptionTextAlign: "center" }}
                />
            )}
        </>
    );
};


export default GalleryInfiniteList;
