import { all, fork } from 'redux-saga/effects';
import user from './user';
import photos from './photos';
import events from './events';
import businesses from './businesses';
import lookups from './lookups';
import gallery from './gallery';

export default function *rootSaga () {
    yield all([
        user,
        photos,
        events,
        businesses,
        lookups,
        gallery
    ].map(fork));
}
