import * as c from '@/redux/constants/gallery';


export const getGalleryEvent = ({ eventSlug }) => ({
    type: c.GET_GALLERY_EVENT,
    eventSlug,
});
export const getGalleryEventSuccess = ({ payload }) => ({
    type: c.GET_GALLERY_EVENT_SUCCESS,
    payload
});
export const getGalleryEventFailure = ({ statusCode }) => ({
    type: c.GET_GALLERY_EVENT_FAILURE,
    statusCode
});

export const getGalleryPhotos = ({ eventSlug, current_page = 1, photoGroupId = null }) => ({
    type: c.GET_GALLERY_PHOTOS,
    eventSlug,
    current_page,
    photoGroupId
});
export const getGalleryPhotosSuccess = ({ payload }) => ({
    type: c.GET_GALLERY_PHOTOS_SUCCESS,
    payload
});
export const getGalleryPhotosFailure = ({ statusCode }) => ({
    type: c.GET_GALLERY_PHOTOS_FAILURE,
    statusCode
});

export const getGalleryPhotoGroups = ({ eventSlug, current_page = 1 }) => ({
    type: c.GET_GALLERY_PHOTO_GROUPS,
    eventSlug,
    current_page
});
export const getGalleryPhotoGroupsSuccess = ({ payload }) => ({
    type: c.GET_GALLERY_PHOTO_GROUPS_SUCCESS,
    payload
});
export const getGalleryPhotoGroupsFailure = ({ statusCode }) => ({
    type: c.GET_GALLERY_PHOTO_GROUPS_FAILURE,
    statusCode
});

export const getGalleryPhotoGroup = ({ eventSlug, photoGroupId }) => ({
    type: c.GET_GALLERY_PHOTO_GROUP,
    eventSlug,
    photoGroupId
});
export const getGalleryPhotoGroupSuccess = ({ payload }) => ({
    type: c.GET_GALLERY_PHOTO_GROUP_SUCCESS,
    payload
});
export const getGalleryPhotoGroupFailure = ({ statusCode }) => ({
    type: c.GET_GALLERY_PHOTO_GROUP_FAILURE,
    statusCode
});