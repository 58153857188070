import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@/components/ui/spinner';
import { getGalleryPhotoGroups } from '@/redux/actions';
import { useParams } from 'react-router-dom';
import GalleryInfiniteList from '@/components/Gallery/GalleryInfiniteList';

const GalleryFaces = () => {
    const { eventSlug } = useParams();
    const dispatch = useDispatch();
    const galleryPhotoGroups = useSelector(state => state.gallery.galleryPhotoGroups);
    const { isGalleryPhotoGroupsLoading } = useSelector(state => state.gallery.state);
    const { current_page, items_total_count, items_per_page, data } = galleryPhotoGroups;
    const hasMore = useMemo(() => current_page * items_per_page < items_total_count, [current_page, items_per_page, items_total_count]);

    const fetchMorePhotos = () => {
        if (hasMore) {
            dispatch(getGalleryPhotoGroups({ eventSlug, current_page: current_page + 1 }));
        }
    };

    useEffect(() => {
        dispatch(getGalleryPhotoGroups({ eventSlug, current_page: 1 }));
    }, [dispatch, eventSlug]);

    if (isGalleryPhotoGroupsLoading && data.length === 0) {
        return (
            <Spinner className="mt-44" size="xlarge">
                <span className="py-3 text-gray-800">Loading faces...</span>
            </Spinner>
        )
    }

    return (
        <GalleryInfiniteList
            data={data}
            hasMore={hasMore}
            next={fetchMorePhotos}
            page={'faces'}
        />

    );
};


export default GalleryFaces;
