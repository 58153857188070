import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/gallery';

const initialState = {
    galleryEvent: {
        id: 0,
        title: '',
        slug: '',
        description: '',
        starting_date: '',
        ending_date: '',
        main_image: '',
        photos_count: 0,
        photo_groups_count: 0,
        public: false,
        business: {
            id: 0,
            name: '',
            logo: ''
        }
    },
    galleryPhotos: {
        current_page: 1,
        items_total_count: null,
        items_per_page: 40,
        data: []
    },
    galleryPhotoGroups: {
        current_page: 1,
        items_total_count: null,
        items_per_page: 40,
        data: []
    },
    galleryPhotoGroup: {
        id: 0,
        preview: '',
        photos_count: 0
    },
    errors: {
        galleryEventStatusCode: null,
        galleryPhotosStatusCode: null,
        galleryPhotoGroupsStatusCode: null,
        galleryPhotoGroupStatusCode: null
    },
    state: {
        isGalleryEventLoading: false,
        isGalleryPhotosLoading: false,
        isGalleryPhotoGroupsLoading: false,
        isGalleryPhotoGroupLoading: false
    },

};

const galleryData = handleActions(
    new Map([
        [
            c.GET_GALLERY_EVENT,
            (state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    galleryEventStatusCode: null
                },
                state: {
                    ...state.state,
                    isGalleryEventLoading: true
                }
            })
        ],
        [
            c.GET_GALLERY_EVENT_SUCCESS,
            (state, { payload }) => ({
                ...state,
                galleryEvent: payload,
                state: {
                    ...state.state,
                    isGalleryEventLoading: false
                },
            })
        ],
        [
            c.GET_GALLERY_EVENT_FAILURE,
            (state, { statusCode }) => ({
                ...state,
                errors: {
                    ...state.errors,
                    galleryEventStatusCode: statusCode
                },
                state: {
                    ...state.state,
                    isGalleryEventLoading: false
                }
            })
        ],
        [
            c.GET_GALLERY_PHOTOS,
            (state, { current_page }) => {
                const data = current_page === 1 ? [] : [...state.galleryPhotos.data];
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        galleryPhotosStatusCode: null
                    },
                    galleryPhotos: {
                        ...state.galleryPhotos,
                        current_page,
                        data
                    },
                    state: {
                        ...state.state,
                        isGalleryPhotosLoading: true
                    }
                }
            }
        ],
        [
            c.GET_GALLERY_PHOTOS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                galleryPhotos: {
                    current_page: payload.current_page,
                    items_total_count: payload.items_total_count,
                    items_per_page: payload.items_per_page,
                    data: [
                        ...state.galleryPhotos.data,
                        ...payload.data
                    ]
                },
                state: {
                    ...state.state,
                    isGalleryPhotosLoading: false
                },
            })
        ],
        [
            c.GET_GALLERY_PHOTOS_FAILURE,
            (state, { statusCode }) => ({
                ...state,
                errors: {
                    ...state.errors,
                    galleryPhotosStatusCode: statusCode
                },
                state: {
                    ...state.state,
                    isGalleryPhotosLoading: false
                }
            })
        ],
        [
            c.GET_GALLERY_PHOTO_GROUPS,
            (state, { current_page }) => {
                const data = current_page === 1 ? [] : [...state.galleryPhotoGroups.data];
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        galleryPhotoGroupsStatusCode: null
                    },
                    galleryPhotoGroups: {
                        ...state.galleryPhotoGroups,
                        current_page,
                        data
                    },
                    state: {
                        ...state.state,
                        isGalleryPhotoGroupsLoading: true
                    }
                }
            }
        ],
        [
            c.GET_GALLERY_PHOTO_GROUPS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                galleryPhotoGroups: {
                    current_page: payload.current_page,
                    items_total_count: payload.items_total_count,
                    items_per_page: payload.items_per_page,
                    data: [
                        ...state.galleryPhotoGroups.data,
                        ...payload.data
                    ]
                },
                state: {
                    ...state.state,
                    isGalleryPhotoGroupsLoading: false
                },
            })
        ],
        [
            c.GET_GALLERY_PHOTO_GROUPS_FAILURE,
            (state, { statusCode }) => ({
                ...state,
                errors: {
                    ...state.errors,
                    galleryPhotoGroupsStatusCode: statusCode
                },
                state: {
                    ...state.state,
                    isGalleryPhotoGroupsLoading: false
                }
            })
        ],
        [
            c.GET_GALLERY_PHOTO_GROUP,
            (state) => {
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        galleryPhotoGroupStatusCode: null
                    },
                    state: {
                        ...state.state,
                        isGalleryPhotoGroupLoading: true
                    }
                }
            }
        ],
        [
            c.GET_GALLERY_PHOTO_GROUP_SUCCESS,
            (state, { payload }) => ({
                ...state,
                galleryPhotoGroup: payload,
                state: {
                    ...state.state,
                    isGalleryPhotoGroupLoading: false
                },
            })
        ],
        [
            c.GET_GALLERY_PHOTO_GROUP_FAILURE,
            (state, { statusCode }) => ({
                ...state,
                errors: {
                    ...state.errors,
                    galleryPhotoGroupStatusCode: statusCode
                },
                state: {
                    ...state.state,
                    isGalleryPhotoGroupLoading: false
                }
            })
        ],
    ]),
    initialState
);

export default galleryData;