import React from 'react';
import { useParams } from 'react-router-dom';


const GalleryPhotoCard = ({ face }) => {
    const { eventSlug } = useParams();
    return (
        <a href={`/gallery/${eventSlug}/faces/${face.id}`}>
            <div className="relative group p-1">
                <img
                    key={face.id}
                    src={face.preview}
                    alt={`face-${face.id}`}
                    className="w-full h-auto rounded-sm"
                />
                <div
                    className="absolute bottom-0 left-0 w-full h-full flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-1">
                    <div className="w-full bg-black bg-opacity-60 p-2">
                        <p className="text-white text-xs underline text-center">View {face.photos_count} photo{face.photos_count > 1 && 's'} &rarr;</p>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default GalleryPhotoCard;
